<template>
  <v-dialog
    v-model="showHistoricModal"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    @click:outside="closeHistoricoModal"
  >
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="closeHistoricoModal()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Histórico de Planos</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <div v-if="planosHistorico.length">
          <div
            v-for="(plano, index) in planosHistorico"
            :key="plano.id"
            :class="index % 2 === 0 ? 'light-background' : 'dark-background'"
            class="planos"
          >
            <v-chip class="mb-4" color="error">
              <v-icon end>delete</v-icon>
              Excluído em: {{ formatarData(plano.deleted_at) }}
            </v-chip>

            <v-chip v-if="plano.gestao_de_aula.disciplina_id == 12" class="mb-4" color="info">
              <v-icon end>child_care</v-icon>
            </v-chip>

            <!-- Verifica se é PLANO REGULAR -->
            <div v-if="plano.gestao_de_aula.disciplina_id != 12" class="ml-5">
              <label>Tema</label>
              <v-input>{{ plano.tematica ?? "---" }}</v-input>

              <label>Objeto de Conhecimento</label>
              <v-input>{{ plano.objeto_conhecimento ?? "---" }}</v-input>

              <label>Habilidades</label>
              <v-input>{{ plano.habilidades ?? "---" }}</v-input>

              <label>Metodologia</label>
              <v-input>{{ plano.metodologia ?? "---" }}</v-input>

              <label>Recursos Didáticos</label>
              <v-input>{{ plano.recursos_didaticos ?? "---" }}</v-input>

              <label>Avaliação</label>
              <v-input>{{ plano.avaliacao_aprendizagem ?? "---" }}</v-input>

              <label>Referências</label>
              <v-input>{{ plano.referencias ?? "---" }}</v-input>
            </div>
            <!-- Verifica se é PLANO INFANTIL -->
            <div v-else-if="plano.gestao_de_aula.disciplina_id == 12" class="ml-5">
              <label>Estratégia de Ação</label>
              <v-input>{{ plano.estrategia_acao ?? "---" }}</v-input>

              <label>Avaliação</label>
              <v-input>{{ plano.avaliacao ?? "---" }}</v-input>

              <label>Referência Bibliográfica</label>
              <v-input>{{ plano.referencia_bibliografica ?? "---" }}</v-input>

              <label>Campos de Experiência</label>
              <v-input v-for="(campo, idx) in plano.experiencia" :key="idx">{{
                campo ?? "---"
              }}</v-input>

              <label>Objetos de Aprendizagem</label>
              <v-input v-for="(obj, idx) in plano.objeto_aprendizagem" :key="idx">{{
                obj ?? "---"
              }}</v-input>

              <label>Direitos de Aprendizagem</label>
              <v-input v-for="(direito, idx) in plano.direitos_aprendizagem" :key="idx">{{
                direito ?? "---"
              }}</v-input>

              <label>Metodologia e Recursos</label>
              <v-input>{{ plano.metodologia_recursos_avaliacao ?? "---" }}</v-input>

              <label>Objeto de Estudo</label>
              <v-input>{{ plano.objeto_estudo ?? "---" }}</v-input>
            </div>
            <v-divider v-if="index < planosHistorico.length - 1" class="my-4"></v-divider>
          </div>
        </div>

        <h2 v-else class="pt-4">Nenhum plano excluído encontrado.</h2>
      </v-card-text>
      <v-card-actions>
        <v-btn size="x-large" color="accent" text @click="closeHistoricoModal">
          <v-icon>mdi-close</v-icon> Fechar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dateFormat } from "../../plugins/filters";

export default {
  name: "HistoricoPlanosModal",
  props: {
    showHistoricModal: {
      type: Boolean,
      required: true,
    },
    planosHistorico: {
      type: Array,
      required: true,
    },
  },
  methods: {
    closeHistoricoModal() {
      this.$emit("close");
    },
    formatarData(date) {
      return dateFormat(date, "d/m/y");
    },
  },
};
</script>
<style>
.light-background {
  background-color: #f5f5f5;
  border-radius: 20px;
  margin-bottom: 10px;
}
.dark-background {
  background-color: #e0e0e0;
  border-radius: 20px;
  margin-bottom: 10px;
}
.planos {
  padding: 40px;
}

.planos label {
  font-size: 22px;
  font-weight: bold;
}
</style>
